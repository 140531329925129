import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Logo from "../../static/img/MA_logo.svg"

const NotFoundPage = () => {
  React.useEffect(() => {
    var delayArray = [1, 2, 3, 4, 5, 6]

    for (var i = 0; i < delayArray.length; i++) {
      const divId = "random" + i
      const delay = delayArray[i] * 1000

      // execute imediately
      //  moveDiv(divId)

      // with timeout
      setTimeout(
        function (a) {
          moveDiv(a)
        },
        delay,
        divId
      )

      // with timeout and then in regular interval
      setTimeout(
        function (a) {
          setInterval(
            function (b) {
              moveDiv(b)
            },
            6000,
            a
          )
        },
        delay,
        divId
      )
    }
  }, [])

  const moveDiv = divId => {
    var mySpan = window.document.getElementById(divId)
    if (mySpan) {
      var maxLeft = window.innerWidth - mySpan.offsetWidth
      var maxTop = window.innerHeight - mySpan.offsetHeight
      var thisLeft = Math.floor(Math.random() * maxLeft)
      var thisTop = Math.floor(Math.random() * (maxTop - 100))
      mySpan.style.left = thisLeft + "px"
      mySpan.style.top = parseInt(thisTop + 100) + "px"
      mySpan.style.opacity = "1"
      setTimeout(() => {
        mySpan.style.opacity = "0"
      }, 5000)
    }
  }

  return (
    <>
    <Helmet>
      <html lang="cs" />
        <meta charSet="utf-8" />
        <title>Magnus Art</title>
        <meta
          name="description"
          content="J&amp;T Banka vybudovala vlastní sbírku současného umění složenou z děl laureátů Ceny Jindřicha Chalupeckého."
        />
      </Helmet>
      <header className="main-header notFoundHeader">
        <div className="main-header__company">
          <Link to="/">
            <img className="main-header__company-logo" src={Logo} alt="Logo" />
          </Link>
        </div>
        <div className="main-header__navigation main-header__navigation-hide">
          <ul>
            <li>
              UMĚNÍ
            </li>
            <li>
            NENALEZENO!
            </li>
          </ul>
        </div>
        <div className="main-header__navigation-mobile hide show">
          <ul>
            <li>
              UMĚNÍ
            </li>
            <li>
            NENALEZENO!
            </li>
          </ul>
        </div>
      </header>
      <div className="notFoundPage">
        <span id="random0">CHYBA</span>
        <span id="random1">404</span>
        <span id="random2">ERROR</span>
        <span id="random3">404</span>
        <span id="random4">404</span>
        <span id="random5">404</span>
      </div>
    </>
  )
}

export default NotFoundPage
